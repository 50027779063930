import { graphql, useStaticQuery } from 'gatsby'
import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'

const NotFoundPage = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useScript(`
    (function(w,a){
    w[a]= (w[a]=w[a]||[]);
    var ec3idMatched = /[?&]ec3id=([^&#]+)/g.exec(location.href);
    if (ec3idMatched && ec3idMatched.length > 1) {
      w[a].push({type:'identify',value:ec3idMatched[1]});
    }
    })(window,'clLayers')
  `)
  useScript(`
    (function(w,d,url,a,i){
    w[a]= (w[a]=w[a]||[]);w[a].push({type:'init',uniqueId:i});
    var s = d.getElementsByTagName("script")[0],e = d.createElement("script");
    e.src=url;e.async=true;s.parentNode.insertBefore(e,s);
    })(window,document,'//rs.cellor-logger.com/analytics.js','clLayers','d01aa27e1c15e94fc07abeb7e67256a06e027655')
  `)

  return (
    <Layout>
      <Helmet>
        <html className="template" lang="ja" />
        <title>{`駐車場トラブル　有人チャットでご質問 | ${title}`}</title>
      </Helmet>
    </Layout>
  )
}

const useScript = (body) => {
  useEffect(() => {
    const script = document.createElement('script')
    script.innerHTML = body
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [body])
}

export default NotFoundPage
